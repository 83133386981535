import { useMemo } from 'react'
import { daysBetween } from 'rrule/dist/esm/dateutil'

import { Button } from '~/components'
import { UnScheduledSurgery } from '~/store/selectors'
import { day, getToday } from '~/utils/extendedDayjs'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'
import { formatDateToNorskReadableIdDate } from '~/utils/utils'

import { isUnScheduledSurgery, WaitingListItem } from '../../WaitingList/shared/columns'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

type Props = {
    surgery: WaitingListItem
    hideAction?: boolean
    onClick?: (selectedSurgery: UnScheduledSurgery) => void
}

export const SurgerySearchOption = ({ surgery, onClick }: Props) => {
    const today = getToday()
    const isUnscheduled = isUnScheduledSurgery(surgery)
    const { patientNameDetailed, dayOvernight, practitioners } = useMemo(() => getFormattedSurgery(surgery), [surgery])

    return (
        <div className="flex items-center justify-between gap-4">
            <div className="flex flex-col gap-1">
                <p className="font-bold">{patientNameDetailed}</p>
                <p className="w-fit rounded bg-slate-100 px-1 text-sm">
                    {isUnscheduled
                        ? `Ventet ${daysBetween(day(surgery.surgeryOrderDetails?.orderedDate).toDate(), today.toDate())} dager`
                        : `Planlagt: ${formatDateToNorskReadableIdDate(day(surgery.contact?.checkInTime)).label}`}
                </p>
                <p>
                    {surgery.surgeryType?.name} - {dayOvernight?.label} -{' '}
                    {practitioners
                        .filter(prac => prac.short_name)
                        .map(p => p.short_name)
                        .join(', ')}
                </p>
            </div>
            {onClick && (
                <div className="w-fit">
                    <Button color="white" size="sm" onClick={() => onClick(surgery)} className="text-nowrap" disabled={!isUnscheduled}>
                        Bestill operasjon
                    </Button>
                </div>
            )}
        </div>
    )
}
