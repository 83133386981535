import dayjs from 'dayjs'

import { ScheduledSurgery, UnScheduledSurgery } from '~/store/selectors'
import { getDayOvernight } from '~/store/slices/filterSlice'

import { formatPersonalId, formatPersonalIdWithLastName, getAssistants, getSurgeons } from '../dips'
import { day, format, formatDuration } from '../extendedDayjs'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

export const getFormattedSurgery = (surgery: ScheduledSurgery | UnScheduledSurgery) => {
    const patient = surgery.patient

    const asa = surgery.surgeryOrderDetails?.asa
    const nprCodeName = surgery.contact?.levelOfCareNpr?.nprCodeName
    const dayOvernight = getDayOvernight(nprCodeName)

    const checkInTime = surgery.contact?.checkInTime !== null ? format(day(surgery.contact?.checkInTime), 'HH:mm') : '--:--'
    const plannedStartTime = 'plannedStartTime' in surgery ? format(day(surgery.plannedStartTime), 'HH:mm') : '--:--'
    const knifeTimeDuration = dayjs.duration(surgery.plannedProcedureDuration ?? zeroDurationToken)
    const knifeTime = knifeTimeDuration.asSeconds() > 0 ? formatDuration(knifeTimeDuration.toISOString()) : '- min'

    const practitioners = [...getSurgeons(surgery.surgeryResources), ...getAssistants(surgery.surgeryResources)]
    const patientName = formatPersonalIdWithLastName(patient)
    const patientNameDetailed = `${surgery.patient?.firstName} ${surgery.patient?.lastName} (${formatPersonalId(surgery.patient?.nationalId ?? '')})`

    return {
        patient,
        patientName,
        patientNameDetailed,
        asa,
        dayOvernight,
        checkInTime,
        knifeTime,
        plannedStartTime,
        practitioners,
    }
}
