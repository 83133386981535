import clsx from 'clsx'
import { HTMLAttributes, PropsWithChildren } from 'react'

export type TagColor =
    | 'primary'
    | 'overbooked'
    | 'location'
    | 'no-bg'
    | 'holiday'
    | 'on-call'
    | 'on-call-white'
    | 'ASA'
    | 'waiting-list'
    | 'new'
    | 'speciality'
    | 'subspeciality'
    | 'composite'
    | 'category'
    | 'none'

export type TagProps = {
    color?: TagColor
    defaultCursor?: boolean
    fullWidth?: boolean
    size?: 'sm' | 'md' | 'lg'
    textSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
}

export const tagColors: Record<TagColor, string> = {
    primary: 'bg-blue-700/20 text-blue-700',
    overbooked: 'bg-white text-red-500',
    location: 'bg-diOrange-400/20 border border-diOrange-400',
    holiday: 'bg-red-200 text-black',
    'on-call': 'bg-neutral-200 text-black',
    'on-call-white': 'bg-white text-black',
    'no-bg': 'text-blue-700',
    ASA: 'bg-fuchsia-200 border-fuchsia-500 border text-black',
    'waiting-list': 'text-blue-700 border-blue-700 border',
    new: 'text-blue-700 border-blue-700 border bg-indigo-50',
    speciality: 'bg-white border-zinc-400 border text-black',
    subspeciality: 'bg-zinc-100 text-black',
    composite: 'bg-zinc-100 text-black',
    category: 'bg-indigo-100 text-black',
    none: 'bg-white text-black',
}
export const textSizes: Record<Exclude<TagProps['textSize'], undefined>, string> = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
}

export const Tag = ({
    children,
    color = 'primary',
    defaultCursor = false,
    fullWidth = false,
    size = 'md',
    textSize = 'xs',
    ...props
}: TagProps & PropsWithChildren & HTMLAttributes<HTMLDivElement>) => (
    <span
        className={clsx(textSizes[textSize], 'rounded', tagColors[color], {
            'font-semibold': color !== 'none',
            'cursor-default': defaultCursor,
            'px-0': color === 'no-bg',
            'px-2': color !== 'no-bg',
            'w-full': fullWidth,
            'w-fit': !fullWidth,
            'py-px': size === 'sm',
            'py-0.5': size === 'md',
            'py-2': size === 'lg',
        })}
        {...props}
    >
        <span className="flex w-full items-center gap-1">{children}</span>
    </span>
)

type HolidayTagProps = TagProps & {
    centerPos?: boolean
}

export const HolidayTag = ({ centerPos = true, ...props }: HolidayTagProps & PropsWithChildren & HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx('flex', { 'justify-center': centerPos })}>
        <Tag color="holiday" {...props}>
            Helligdag
        </Tag>
    </div>
)
