import { useState } from 'react'

import { RadioButtons } from '~/components'
import env from '~/env'
import { useStore } from '~/store/store'

import { BookingContent } from './Booking/BookingContent'
import { Contents } from './Contents'
import { WaitingListDrawerHeader } from './Header'

type FilterTypes = 'book' | 'patientInfo'
type FilterOption = { label: string; value: FilterTypes }

const listOptions: FilterOption[] = [
    { label: 'Om Inngrepet', value: 'book' },
    { label: 'Pasientinformasjon', value: 'patientInfo' },
]
export const WaitingListSidebar = () => {
    const [listFilter, setListFilter] = useState<FilterTypes>(env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER ? 'book' : 'patientInfo')
    const bookingId = useStore(state => state.waitingList.drawerProps?.item.BookingId)

    return (
        <div className="flex h-full w-[30rem] flex-col gap-4 pl-4 pr-2" key={bookingId} data-test="Waitinglist Sidebar">
            <div className="flex">
                <div className="grow">
                    <WaitingListDrawerHeader />
                </div>
            </div>

            <div className="flex flex-col gap-4 overflow-auto">
                {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER && (
                    <RadioButtons options={listOptions} onChange={selected => setListFilter(selected)} value={listFilter} />
                )}
                {listFilter === 'book' && <BookingContent />}
                {listFilter === 'patientInfo' && <Contents />}
            </div>
        </div>
    )
}
