import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

import { BookingDialog } from '~/app/pages/OperationalPlanner/BookingDrawer/BookingDialog/BookingDialog'
import { SurgeryCard } from '~/app/pages/OperationalPlanner/BookingDrawer/SurgeryCard'
import { Show, Tag } from '~/components'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { HomeHealthOutlined } from '~/icons'
import { OccupancyData } from '~/store/selectors'
import { getDayOvernightTooltipText, isDayOvernightLabel } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { format, getToday } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'

import { useGetWaitingListSurgery } from '../../hooks/useGetWaitingListSurgery'
import { useSaveSurgeryMetadata } from '../../hooks/useSaveSurgeryMetadata'
import { columns } from '../../shared/columns'
import { Remark } from '../../shared/Remarks'
import { getCompletedStatus, Status } from '../../shared/Statuses'
import { AvailableDaysPicker } from './AvailableDaysPicker'
import { AvailableLocations } from './AvailableLocations'
import { ClearedRadioGroup } from './ClearedRadioGroup'
import { SidebarSwitch } from './SidebarSwitch'

export const BookingContent = () => {
    const today = getToday()

    const [selectedDay, setSelectedDay] = useState<Dayjs | undefined>()
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const [month, setMonth] = useState<Dayjs>(today.startOf('month'))
    const [showAllPractitioners, setShowAllPractitioners] = useState<boolean>(false)
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)
    const [selectedOccupancy, setSelectedOccupancy] = useState<OccupancyData>()

    const monthRange = useMemo(
        () => ({
            startTime: format(month.startOf('month')),
            endTime: format(month.endOf('month').add(1, 'day')),
        }),
        [month]
    )

    // needed to derive the open slots for the selected surgery type
    const { isLoading } = useImportOccupancyEntities(monthRange.startTime, monthRange.endTime)

    const { BookingId, BookedStatus, Day } = drawerProps?.item ?? {}
    const isUnscheduledItem = BookedStatus !== 'Scheduled'

    const selectedSurgery = useGetWaitingListSurgery(drawerProps?.item)

    const saveSurgeryMetadata = useSaveSurgeryMetadata(BookingId ?? '', selectedSurgery?.surgeryMetadata)

    const isPatientReady = selectedSurgery?.surgeryMetadata?.patient_ready

    const isNew = Boolean(isUnscheduledItem && isNullish(isPatientReady))
    const isCleared = Boolean(isPatientReady)
    const isConfirmed = Boolean(selectedSurgery?.surgeryMetadata?.patient_confirmed)
    const isShortNotice = Boolean(selectedSurgery?.surgeryMetadata?.patient_short_notice)
    const isPrio = Boolean(selectedSurgery?.surgeryMetadata?.patient_prioritized)

    const onCloseDialog = () => {
        setSelectedOccupancy(undefined)
        setShowBookingDialog(false)
    }

    if (drawerProps === null || !selectedSurgery) return <div />

    return (
        <div className="flex flex-col gap-4" data-test="waiting-list-booking-content">
            <p className="font-bold">{isUnscheduledItem ? 'Til Planlegging' : 'Planlagt'}</p>
            {BookingId && <SurgeryCard hideAction surgery={selectedSurgery} />}

            <div className="flex flex-col gap-2">
                <div className="flex gap-1">
                    <p className="font-bold">Pasientstatus</p>
                    <Show condition={isNew}>
                        <Tag size="sm" color="new">
                            Ny
                        </Tag>
                    </Show>
                </div>
                <ClearedRadioGroup isUnscheduledItem={isUnscheduledItem} isNew={isNew} isCleared={isCleared} onChange={saveSurgeryMetadata} />
                <Show condition={!isUnscheduledItem}>
                    <SidebarSwitch
                        label="Bekreftet oppmøte"
                        checked={isConfirmed}
                        onCheckedChange={checked => saveSurgeryMetadata({ patient_confirmed: checked })}
                    >
                        <Status status={{ type: 'confirmed', value: getCompletedStatus(isConfirmed) }} isSelected={isConfirmed} />
                    </SidebarSwitch>
                </Show>
            </div>

            <div className="flex flex-col gap-2">
                <p className="font-bold">{columns.Remarks.label}</p>
                {Day && !isDayOvernightLabel(Day) && (
                    <div className="bg-yellow-100 px-4 py-4">
                        <p className="flex gap-2">
                            <HomeHealthOutlined className="shrink-0" />
                            {getDayOvernightTooltipText(Day)}
                        </p>
                    </div>
                )}
                <SidebarSwitch label="Kort varsel" checked={isShortNotice} onCheckedChange={checked => saveSurgeryMetadata({ patient_short_notice: checked })}>
                    <Remark remark="shortNotice" color={isShortNotice ? 'on' : 'off'} />
                </SidebarSwitch>
                <SidebarSwitch label="Prioritet" checked={isPrio} onCheckedChange={checked => saveSurgeryMetadata({ patient_prioritized: checked })}>
                    <Remark remark="prioritized" color={isPrio ? 'on' : 'off'} />
                </SidebarSwitch>
            </div>

            {isUnscheduledItem && (
                <div className="mt-4 flex flex-col gap-2">
                    <p className="font-bold">Velg operasjonstid</p>
                    <label className="mt-2 flex w-full hover:cursor-pointer">
                        <input
                            type="checkbox"
                            data-test="show-all-practitioners-checkbox"
                            className="accent-indigo-700 hover:cursor-pointer"
                            checked={showAllPractitioners}
                            onChange={e => {
                                setShowAllPractitioners(e.target.checked)
                            }}
                        />
                        <p className="ml-2">Vis ledige tider for alle kirurger</p>
                    </label>
                    <AvailableDaysPicker
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        surgery={selectedSurgery}
                        selectedMonth={month}
                        onMonthChange={setMonth}
                        isLoading={isLoading}
                        showAllPractitioners={showAllPractitioners}
                    />
                    <AvailableLocations
                        isLoading={isLoading}
                        selectedDay={selectedDay}
                        selectedMonth={month}
                        showAllPractitioners={showAllPractitioners}
                        surgery={selectedSurgery}
                        onClick={occ => {
                            setSelectedOccupancy(occ)
                            setShowBookingDialog(true)
                        }}
                    />
                </div>
            )}
            {selectedOccupancy && (
                <BookingDialog isOpen={showBookingDialog} onCloseDialog={onCloseDialog} waitingListItem={selectedSurgery} occupancyData={selectedOccupancy} />
            )}
        </div>
    )
}
