import { useStore } from '~/store/store'
import { getAgeAndPersonalId, getFullNameAndMunicipality } from '~/utils/dips'

import { useGetWaitingListSurgery } from '../hooks/useGetWaitingListSurgery'

export const WaitingListDrawerHeader = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const surgery = useGetWaitingListSurgery(drawerProps?.item)

    const fullNameAndMunicipality = getFullNameAndMunicipality(surgery?.patient)
    const ageAndPersonalId = getAgeAndPersonalId(surgery?.patient)

    if (drawerProps === null) return <div />

    return (
        <header>
            <div className="flex flex-col">
                <div className="text-base font-bold">{fullNameAndMunicipality}</div>
                <div>{ageAndPersonalId}</div>
            </div>
        </header>
    )
}
